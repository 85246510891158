<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        ECA Exam Setting
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="exam-create" v-if='exam_id && grade'
                                        @action="viewExamSettingdDetail">
                                &nbsp; Add/Edit
                            </add-button>
                        </transition>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs5>
                                <v-select :items="exams" class="pa-0" label="Exam" v-model="exam_id" outlined dense/>
                            </v-flex>
                            <v-flex xs3>
                                <v-select :loading="loadingGrade"
                                          :disabled="!grades.length"
                                          :items="grades" class="pa-0"
                                          label="Grade"
                                          v-model="grade" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table
                            :headers="headers"
                            :hide-default-footer="form.items.data.length<11"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    <strong>{{ item.name }}</strong>
                                </td>
                                <td class="text-xs-left">
                                    <div v-if="item.lists">
                                        <p v-for="(data, i) in item.lists" :key="i" style="margin-top:5px">
                                            {{data.name}}
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            form: new Form({
                name: '',
            }, '/api/eca-exam-list'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 100,
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'ECA', align: 'left', value: 'name'},
                {text: 'List', align: 'left', value: 'list'},
            ],
            exams: [],
            grades: [],
            exam_id: '',
            grade: '',
            loadingGrade: false,

        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'grade': function () {
                this.get();
            },
            'exam_id': function (v) {
                this.grade = '';
                this.form.items.data = [];
                this.getGrades();
            }
        },
        mounted() {
            this.getExams();
            // this.getGrades();
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade + '&examId=' + this.exam_id;
            },

            get(params) {
                if (this.grade && this.exam_id) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.pagination.totalItems = data.meta.total
                    })
                }
            },

            save() {
                this.form.store();
            },

            getExams() {
                this.$rest.get('/api/exam').then(({data}) => {
                    this.exams = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            getGrades() {
                this.loadingGrade = true;
                this.$rest.get('/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=' + this.exam_id).then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.grade_id, text: item.name}
                    });
                }).finally(() => {
                    this.loadingGrade = false;
                })
            },
            viewExamSettingdDetail() {
                this.$router.push({
                    name: 'eca-exam-setting-detail',
                    params: {
                        gradeId: this.grade,
                        examId: this.exam_id,
                    }
                })
            }

        }
    }
</script>
<style lang="scss" scoped></style>